import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { useAuth } from "./core/Auth";
import { AuthModel } from "./core/_models";
import { getUserByToken } from "./core/_requests";

const JwtCheck: FC = ()  => {
    const navigate = useNavigate();
    const {saveAuth, setCurrentUser} = useAuth();
    let { jwt } = useParams();

    useEffect(() => {
        async function sendCheckRequest(jwt: string | undefined) {
            try {
                if(!!jwt && isJwt(jwt)) {
                    const {data: user} = await getUserByToken(jwt);
                    const modal: AuthModel = {
                        api_token: jwt
                    };
                    saveAuth(modal);
                    setCurrentUser(user);
                    navigate('/');
                }
                else {
                    saveAuth(undefined);
                    navigate('/auth');
                }
            }
            catch {
                saveAuth(undefined);
                navigate('/auth');
            }
        }
        sendCheckRequest(jwt);
    })

    function isJwt(jwt: string): boolean {
        try {
            var base64Url = jwt.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            JSON.parse(jsonPayload);
            return true;
        }
        catch {
            return false;
        }
    }
    return (
    <div
    id="kt_body"
    data-bs-spy="scroll"
    data-bs-target="#kt_landing_menu"
    data-bs-offset="200"
    className="bg-white position-relative">
        <div id="root" className="d-flex flex-column flex-root"></div>
        <div id="splash-screen" className="splash-screen">
        <img
            src={toAbsoluteUrl("/favicon.ico")}
            alt="Logo"
            className="h-30px"
        />
        <span>Loading ...</span>
        </div>
        <div id="root-modals"></div>
    </div>
    )
}

export default JwtCheck;
