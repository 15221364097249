export enum Constants {
    CustomerAthora = 'Athora',
    CustomerScildon = 'Scildon',
    CustomerCbPpi = 'cbppi',
    TripleA = 'triplea'
}

export enum FileType {
    inputFile = 'inputFile',
    outputFile = 'outputFile',
    reportFile = 'reportFile',
    errorFile = 'errorFile'
}