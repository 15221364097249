import { Constants } from "../../../Constants/Constants";

let toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname
const API_URL = process.env.REACT_APP_API_URL

export function Footer() {
  const environmentCustomer = process.env.REACT_APP_CUSTOMER_NAME;
  let isNotTripleA = environmentCustomer!.toUpperCase() !== Constants.TripleA.toUpperCase();

  return (
    <div className='mb-0'>
      {isNotTripleA ?
        <div className='landing-dark-bg pt-10 pb-10'>
          <div className='container'>
            <div className='d-flex flex-column'>
              <div
                className='col-lg-6X pe-lg-16X mb-10X mb-lg-0X'
                data-grid='true'
                data-grid-item-size='md'
              >
              <span className='d-flex align-center'>
                <a className='footer-link' target="_blank" rel="noreferrer" href={ API_URL + "/generalDocuments/manualDocument" }>Handleiding</a>
              </span>
              <span className='d-flex align-center'>
                <a className='footer-link' target="_blank" rel="noreferrer" href={ API_URL + "/generalDocuments/faqDocument" }>FAQ</a>
              </span>
              <span className='d-flex align-center'>
                <a className='footer-link' target="_blank" rel="noreferrer" href={ API_URL + "/generalDocuments/conditionsDocument" }>Voorwaarden</a>
              </span>
              </div>
            </div>
          </div>
        </div>
        :
        <div className='landing-dark-bg pt-20'>
          <div className='container'>
            <div className='rowX py-10 py-lg-20'>
              <div
                className='col-lg-6X pe-lg-16X mb-10X mb-lg-0X'
                data-grid='true'
                data-grid-item-size='md'
              >
                <div className='rounded landing-border p-9'>
                  <h5 className='text-white'>Triple A - Risk Finance</h5>
                  <div className='separator border-2 my-4'></div>

                  <div className='d-flex flex-column'>
                    <span className='d-flex align-items-start'>
                      <span className='badge badge-square'>
                        <i className='fas fa-solid fa-location-arrow [ fs-5 ] icon-only'></i>
                      </span>
                      <span className='ms-2 text-white'>
                        Hoogoorddreef 54, 1101 BE Amsterdam Zuidoost
                      </span>
                    </span>

                    <span className='d-flex align-items-center [ my-2 ]'>
                      <span className='badge badge-square'>
                        <i className='fas fa-solid fa-phone [ fs-5 ] icon-only'></i>
                      </span>
                      <a href='tel:0207073640' className='ms-2 text-white text-hover-primary'>
                        020 - 707 36 40
                      </a>
                    </span>

                    <span className='d-flex align-items-center'>
                      <span className='badge badge-square'>
                        <i className='fas fa-solid fa-envelope [ fs-5 ] icon-only'></i>
                      </span>
                      <a href='mailto:info@aaa-riskfinance.nl' className='ms-2 text-white text-hover-primary'>
                        info@aaa-riskfinance.nl
                      </a>
                    </span>
                  </div>
                </div>

                <div className='rounded landing-border p-9'>
                  <h5 className='text-white'>Volg ons</h5>
                  <div className='separator border-2 my-4'></div>

                  <div className='d-flex justify-content-start'>
                    <a href='https://www.linkedin.com/company/triple-a---risk-finance' className='btn btn-icon icon-circled'>
                      <i className='fab fa-brands fa-linkedin-in [ fs-1 ] icon-circled-text'></i>
                    </a>
                    <a href='https://www.facebook.com/aaariskfinance/' className='btn btn-icon icon-circled [ mx-2 ]'>
                      <i className='fab fa-brands fa-facebook-f [ fs-1 ] icon-circled-text'></i>
                    </a>
                    <a href='https://www.youtube.com/channel/UCTJ-3Q3EnBXB73qP-hdQp6g' className='btn btn-icon icon-circled'>
                      <i className='fab fa-brands fa-youtube [ fs-1 ] icon-circled-text'></i>
                    </a>
                  </div>
                </div>

                <div className='rounded landing-border p-9'>
                  <h5 className='text-white'>Informatie</h5>
                  <div className='separator border-2 my-4'></div>

                  <div className='d-flex flex-column'>
                    <span className='d-flex align-items-start flex-column'>
                      <a
                        href='https://www.aaa-riskfinance.nl/app/uploads/2018/08/2018-DVD-TARF_-PENSIOEN_2018.pdf'
                        className='text-white opacity-50X text-hover-primary small'
                      >
                        Dienst VerleningsDocument DVD
                      </a>
                      <a
                        href='https://www.aaa-riskfinance.nl/app/uploads/2022/03/Algemene-Voorwaarden-DetacheringConsultancy-Redmore-Juli-2021-DEF.pdf'
                        className='text-white opacity-50X text-hover-primary small [ mt-3 ]'
                      >
                        Algemene voorwaarden
                      </a>
                      <a
                        href='https://www.aaa-riskfinance.nl/klachtenprocedure/'
                        className='text-white opacity-50X text-hover-primary small [ mt-3 ]'
                      >
                        Klachtenprocedure
                      </a>
                      <a
                        href='https://www.aaa-riskfinance.nl/disclaimer/'
                        className='text-white opacity-50X text-hover-primary small [ mt-3 ]'
                      >
                        Disclaimer
                      </a>
                      <a
                        href='https://www.aaa-riskfinance.nl/privacy-policy/'
                        className='text-white opacity-50X text-hover-primary small [ mt-3 ]'
                      >
                        Privacyverklaring en Disclaimer Triple A-Risk Finance
                      </a>
                    </span>
                  </div>
                </div>

                <div className='rounded landing-border p-9'>
                  <h5 className='text-white'>A Redmore Company</h5>
                  <div className='separator border-2 my-4'></div>

                  <div className='d-flex flex-column'>
                    <span className='d-flex align-items-start flex-column'>
                      <a href='https://www.profource.com/' className='mt-3'>
                        <img
                          src={toAbsoluteUrl('/media/tripleA/footer-logo-profource.svg')}
                          alt=''
                          className='img-fluid [ min-h-40px ]'
                        />
                      </a>

                      <a href='https://www.talent-pro.com/' className='mt-3'>
                        <img
                          src={toAbsoluteUrl('/media/tripleA/footer-logo-talentAndPro.svg')}
                          alt=''
                          className='img-fluid [ min-h-40px ]'
                        />
                      </a>

                      <a href='https://itds.nl/' className='mt-3'>
                        <img
                          src={toAbsoluteUrl('/media/tripleA/footer-logo-itds.svg')}
                          alt=''
                          className='img-fluid [ min-h-40px ]'
                        />
                      </a>
                    </span>
                  </div>

                  <div className='separator border-2 my-4'></div>

                  <a href='https://www.pensioenperspectief.nl/' className='mt-3'>
                    <img
                      src={toAbsoluteUrl('/media/tripleA/footer-logo-tripleA.svg')}
                      alt=''
                      className='img-fluid [ min-h-40px ]'
                    />
                  </a>
                </div>
              </div>
            </div>
            {/* <!--end::Container--> */}
            {/* <!--begin::Separator--> */}
            <div className='landing-separator'></div>
            {/* <!--end::Separator--> */}
            {/* <!--begin::Container--> */}
            <div className='container'>
              {/* <!--begin::Wrapper--> */}
              <div className='d-flex flex-column flex-md-row flex-stack py-7 py-lg-10'>
                {/* <!--begin::Copyright--> */}
                <div className='d-flex align-items-center order-2 order-md-1'>
                  {/* <!--begin::Logo--> */}
                  <a href='https://www.pensioenperspectief.nl/'>
                    <img
                          src={toAbsoluteUrl('/media/tripleA/footer-logo-tripleA.svg')}
                          alt=''
                          className='img-fluid [ h-15px h-md-30px ]'
                        />
                  </a>
                  {/* <!--end::Logo image--> */}
                  {/* <!--begin::Logo image--> */}
                  <span className='mx-5 fs-6 fw-bold text-white pt-1'>
                    {' '}
                    Copyright &copy; {new Date().getFullYear()}. Alle rechten voorbehouden.
                  </span>
                  {/* <!--end::Logo image--> */}
                </div>
                {/* <!--end::Copyright--> */}
                {/* <!--begin::Menu--> */}
                <ul className='menu menu-white menu-hover-primary fw-bold fs-6 fs-md-5 order-1 mb-5 mb-md-0'>
                  <li className='menu-item'>
                    <a href='https://www.aaa-riskfinance.nl/en/about-us/' target='_blank' rel="noreferrer" className='menu-link px-2'>
                      About
                    </a>
                  </li>
                </ul>
                {/* <!--end::Menu--> */}
              </div>
              {/* <!--end::Wrapper--> */}
            </div>
            {/* <!--end::Container--> */}
          </div>
        </div>
      }
    </div>
  )
}
