import React from 'react'
import { KTSVG } from '../../../_metronic/helpers'

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <form className='w-100 position-relative [ d-flex ]' autoComplete='off'>
      <KTSVG
        path='/media/icons/duotune/general/gen021.svg'
        className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
      />

      <input
        type='text'
        className='form-control form-control-solid px-15'
        name='search'
        placeholder='Zoeken binnen deze lijst..'
        value={filter || ''}
        onChange={(e) => setFilter(e.target.value)}
      />
    </form>
  )
}