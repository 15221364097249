import {FC} from 'react'

const HeaderUserMenu: FC = () => {
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
    </div>
  )
}

export {HeaderUserMenu}
