import {Link, useLocation, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import customData from '../../../custom-settings/CustomText.json'


import {useAuth} from '../auth'

export function CustomHeader() {
  const location = useLocation()
  const {id} = useParams()
  const {currentUser, logout} = useAuth()
  let imgAlt = customData.logo.alt;
  let imgSrc = customData.logo.src;

  return (
    <div className='landing-header' data-kt-sticky='true' data-kt-sticky-name='landing-header'>
      <div className='container'>
        {/* begin: Wrapper */}
        <div className='d-flex align-items-center justify-content-between'>
          {/* begin: Logo */}
          <Link to='/' className=''>
            <img
              alt={imgAlt}
              src={toAbsoluteUrl(imgSrc)}
              className='logo-default h-20px h-lg-55px'
            />
            <img
              alt={imgAlt}
              src={toAbsoluteUrl(imgSrc)}
              className='logo-sticky h-20px h-lg-55px'
            />
          </Link>

          <div className='d-flex align-items-center'>
            {(location.pathname === `/client-list` || location.pathname === `/client-overview/${id}`) && (
              <Link to='/' className='btn btn-standard ms-4'>
                <span>Home</span>
              </Link>
            )}

            {(location.pathname === `/client-overview/${id}` || location.pathname === `/`) && (
              <Link to='/client-list' className='btn btn-standard ms-4'>
                <span>Overzicht werkgevers</span>
              </Link>
            )}
            
            {currentUser && (
              <Link
                to='/auth/logout'
                className='btn btn-standard ms-4'
                onClick={logout}
              >
                <span>Uitloggen</span>
              </Link>
            )}
          </div>
          {/* end: Toolbar */}
        </div>
        {/* end: Wrapper */}
      </div>
    </div>
  )
}
