import {KTSVG} from '../../../../_metronic/helpers'

export function DeleteDocumentModal(props) {
  return (
    <div className='modal fade' tabIndex={-1} id='modalDeleteDocument'>
      <div className='modal-dialog'>
        <div className='modal-content [ light-branded-bg ]'>
          <div className='modal-header'>
            <h5 className='modal-title'>Dossier verwijderen</h5>
            <div
              className='btn btn-icon btn-sm btn-light btn-modal-icon ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr011.svg'
                className='svg-icon-muted svg-icon-2hx'
              />
            </div>
          </div>
          <div className='modal-body'>
            <p className='fs-5 text-gray-800'>Weet u zeker dat u dit dossier wilt verwijderen?</p>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-inverted-light'
              data-bs-dismiss='modal'
              onClick={() => props.deleteDocHandler()}
            >
              Ja
            </button>
            <button type='button' className='btn btn-standard' data-bs-dismiss='modal'>
              Nee
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
