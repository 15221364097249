import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

// Validation imports
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {Constants} from '../../../../Constants/Constants'

var fileInputKey = Math.random().toString(36);

const environmentCustomer = process.env.REACT_APP_CUSTOMER_NAME; 
let onlyDcToDc = environmentCustomer.toUpperCase() === Constants.CustomerScildon.toUpperCase() || 
                 environmentCustomer.toUpperCase() === Constants.CustomerCbPpi.toUpperCase();

function resetFileInputKey() {
  let randomString = Math.random().toString(36);
  fileInputKey = randomString;
}

const initialValues = {
  name: '',
  file: null
}

const SUPPORTED_FORMATS = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]

const fileUploadSchema = Yup.object().shape({
  name: Yup.string('')
    .min(1, 'Minimum 1 characters')
    .max(30, 'Maximum 30 characters')
    .matches(
      /[a-zA-Z0-9][a-zA-Z0-9-. ]+$/,
      'Must start with an alphanumeric character and only contain letters, whitespace, numbers, dots and dashes.'
    )
    .required('Name is required'),
  file: Yup.mixed()
    .required('File is required')
    .test(
      'FILE_SIZE',
      'File size is too large.',
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    )
    .test(
      'FILE_FORMAT',
      'Unsupported file format. Please upload .xlsx files only.',
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
    ),
})

export function NewFileModal({ ...props }) {
  const formik = useFormik({
    initialValues,
    validationSchema: fileUploadSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm, setFieldValue }) => {
      try {
        const formData = new FormData()
        formData.append('documentName', values.name.trim())
        formData.append('document', values.file)
        await props.uploadDocHandler(formData)
        resetForm({values: ''})
        resetFileInputKey();
      } catch (err) {
        setSubmitting(false)
        setStatus('The file was not uploaded.')
      }
    },
  })

  return (
    <div className='modal fade' tabIndex={-1} id='modalNewFile'>
      <div className='modal-dialog modal-xl'>
        <div className='modal-content [ light-branded-bg ]'>
          <div className='modal-header'>
            <h5 className='modal-title [ t-primary ]'>Nieuw dossier toevoegen</h5>
            <div
              className='btn btn-icon btn-sm btn-light btn-modal-icon ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr011.svg'
                className='svg-icon-muted svg-icon-2hx'
              />
            </div>
          </div>
          <div className='modal-body'>
            <p className='fs-3 w-75'>
              Vul de naam in van het dossier dat u wilt doorrekenen en upload een ingevuld input
              template.
            </p>

            <form className='form' onSubmit={formik.handleSubmit}>
              <div className='input-group mb-3'>
                <input
                  type='text'
                  name='name'
                  autoComplete="off"
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  placeholder='Naam dossier'
                  aria-label='Naam dossier'
                />
                <input
                  id='fileInput'
                  type='file'
                  key={fileInputKey || '' }
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.file && formik.errors.file},
                    {
                      'is-valid': formik.touched.file && !formik.errors.file,
                    }
                  )}
                  placeholder='Upload File'
                  aria-label='Upload File'
                  onChange={(e) => {
                    formik.handleChange(e)
                    formik.setFieldValue('file', e.target.files[0])
                  }}
                />
                <button
                  className='btn btn-standard'
                  type='submit'
                  id=''
                  data-bs-dismiss={!formik.errors.name && !formik.errors.file ? 'modal' : null}
                >
                  Toevoegen
                </button>
              </div>

              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block text-center'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
              {formik.touched.file && formik.errors.file && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block text-center'>
                    <span role='alert'>{formik.errors.file}</span>
                  </div>
                </div>
              )}

              <div className='bottom-section'>
                <div className='w-75'>
                  <p className='fs-6 mt-20'>
                  {onlyDcToDc ? 'Hieronder kunt u een leeg input template downloaden.' : 'Hieronder kunt u de lege input templates downloaden voor de verschillende type regelingen.'}
                  </p>
                  <div data-grid='true' data-grid-item-size='md' data-grid-fill='auto-fill'>
                    <button
                      onClick={() => props.downloadTemplate('dcToDc')}
                      type='button'
                      className='btn btn-inverted-light [ d-flex justify-content-center align-items-center ]'
                    >
                      <span className='fs-5'>DC</span>
                      <i className='bi bi-arrow-right [ fs-5 ]'></i>
                      <span className='fs-5'>DC</span>
                      <i className='bi bi-file-earmark-excel-fill [ fs-5 ]'></i>
                    </button>

                    <button
                      onClick={() => props.downloadTemplate('dbToDc')}
                      type='button'
                      className={`btn btn-inverted-light ${onlyDcToDc ? "d-none" : "" } [ d-flex justify-content-center align-items-center ]`}
                    >
                      <span className='fs-5'>DB</span>
                      <i className='bi bi-arrow-right [ fs-5 ]'></i>
                      <span className='fs-5'>DC</span>
                      <i className='bi bi-file-earmark-excel-fill [ fs-5 ]'></i>
                    </button>

                    <button
                      onClick={() => props.downloadTemplate('ambitionDbToDc')}
                      type='button'
                      className={`btn btn-inverted-light ${onlyDcToDc ? "d-none" : "" } [ d-flex justify-content-center align-items-center ]`}
                    >
                      <span className='fs-5'>Ambitie DB</span>
                      <i className='bi bi-arrow-right [ fs-5 ]'></i>
                      <span className='fs-5'>DC</span>
                      <i className='bi bi-file-earmark-excel-fill [ fs-5 ]'></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
