import { Fragment } from 'react'
import { Link } from 'react-router-dom';


export function ActionsColumnButtons({...props}) {
  return (
    <Fragment>
      <span className='d-flex justify-content-center [ actions_column ]'>
        <button
          onClick={() => props.currentClientDetailSetter()}
          className='btn btn-hover-inverted btn-icon btn-sm'
          data-bs-toggle='modal'
          data-bs-target='#modalEditClient'
          data-tip='Edit'
        >
          <i className='bi bi-pen fs-4'></i>
        </button>
        <Link
          to={`../client-overview/${props.clientId}`}
          className='btn btn-icon btn-inverted-dark btn-sm'
          data-tip='Overview'
        >
          <i className='bi bi-folder-fill fs-4'></i>
        </Link>
        <button
          onClick={() => props.currentClientDetailSetter()}
          className='btn btn-light btn-active-light-danger btn-icon btn-sm'
          data-bs-toggle='modal'
          data-bs-target='#modalDeleteClient'
          data-tip='Delete'
        >
          <i className='bi bi-x-lg fs-4'></i>
        </button>
      </span>
    </Fragment>
  )
}

export function DocsActionsColumn({...props}) {
  return (
    <span className='d-flexX justify-content-centerX [ actions_columnX ]'>
      <button
        onClick={() => props.setDocToDeleteId()}
        className='btn btn-light btn-active-light-danger btn-icon btn-sm'
        data-bs-toggle='modal'
        data-bs-target='#modalDeleteDocument'
      >
        <i className='bi bi-x-lg fs-4'></i>
      </button>
    </span>
  )
}

