import {toAbsoluteUrl} from '../../../_metronic/helpers'
import customData from '../../../custom-settings/CustomText.json'

export function Hero() {
  let text = customData.heropage.text;
  let imageUrl = customData.heropage.imageUrl;
  let backgroundPositions = customData.heropage.backgroundPositions;

  return (
    <div
      className='d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9 [ hero-bg-v2 ] [ hero-bg-imgX ]'
      style={{
        background: `url(${toAbsoluteUrl(imageUrl)})
         center center no-repeat`,
        backgroundSize: 'cover',
        backgroundPosition: backgroundPositions
      }}
    >
      <div className='container' data-grid='true' data-grid-item-size='md'>
        <div className='card card-body [ mt-4 ] [ card__hero custom-opacity ]'>
          <div className='text-centerX mb-5X mb-lg-10X py-10X py-lg-20X'>
            <h1 className='header-font t-primary lh-base fw-bold fs-1xXXX fs-lg-4xXXX mb-15X mb-0'>
              {text}
            </h1>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  )
}
