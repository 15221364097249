import React, { useState } from 'react'

// Validation imports
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'

export function ContactForm() {
  return (
    <div className='' data-custom-form='true'>
      <div className='mb-3 [ grid-item ]'>
        <label className='form-label [ required ]'>Uw vraag</label>
        <textarea className='form-control'></textarea>
      </div>
      <div className='mb-3 [ grid-item ]'>
        <label className='form-label [ required ]'>Naam</label>
        <input type='text' className='form-control' placeholder='' />
      </div>

      <div className='mb-3 [ grid-item ]'>
        <label className='form-label [ required ]'>Organisatie</label>
        <input type='text' className='form-control' placeholder='' />
      </div>

      <div className='mb-3 [ grid-item ]'>
        <label className='form-label [ required ]'>Telefoonnummer</label>
        <input type='tel' className='form-control' placeholder='' />
      </div>

      <div className='mb-3 [ grid-item ]'>
        <label className='form-label [ required ]'>E-mailadres</label>
        <input type='email' className='form-control' placeholder='' />
      </div>
    </div>
  )
}

const initialValues = {
  message: '',
  name: '',
  orginization: '',
  telephone: '',
  email: '',
}

const contactFormSchema = Yup.object().shape({
  message: Yup.string('')
    .min(10, 'Minimum 10 characters')
    .max(1000, 'Maximum 1000 characters')
    .required('Message is required'),
  name: Yup.string('')
    .min(2, 'Minimum 2 characters')
    .max(30, 'Maximum 30 characters')
    .required('Name is required'),
  orginization: Yup.string('')
    .min(3, 'Minimum 3 characters')
    .max(30, 'Maximum 30 characters')
    .required('Orginization is required'),
  telephone: Yup.string('')
    .min(10, 'Minimum 10 digits')
    .max(15, 'Maximum 15 characters')
    .required('Phone number is required'),
  email: Yup.string('')
    .email('Wrong email format')
    .required('Email is required'),
})

export function ContactFormTwo(props) {
  const [sending, setSending] = useState(false)
  // const [loading, setLoading] = useState(false)
  // const [hasErrors, setHasErrors] = useState()
  const formik = useFormik({
    initialValues,
    validationSchema: contactFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setSending(true)
        await props.sendContactForm(
          values.message.trim(),
          values.name.trim(),
          values.orginization.trim(),
          values.telephone.trim(),
          values.email.trim()
        )
        // setLoading(false)
      } catch(err) {
        // setHasErrors(true)
        // setLoading(false)
        setSubmitting(false)
        setStatus('The form was not sent.')
      }
        // setHasErrors(false)
        setSending(false)
    }
  })

  return (
    <form id='contact-form-two' onSubmit={formik.handleSubmit}>
      <div className='w-75 mx-auto'>
        <div className='fv-row mb-3'>
          <label className='form-label [ required ]'>Uw vraag</label>
          <textarea
            name='message'
            {...formik.getFieldProps('message')}
            className={clsx(
              'form-control form-control-white',
              {'is-invalid': formik.touched.message && formik.errors.message},
              {
                'is-valid': formik.touched.message && !formik.errors.message,
              }
            )}
          ></textarea>
          {formik.touched.message && formik.errors.message && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.message}</span>
              </div>
            </div>
          )}
        </div>

        <div className='mb-3'>
          <label className='form-label [ required ]'>Naam</label>
          <input
            name='name'
            {...formik.getFieldProps('name')}
            className={clsx(
              'form-control form-control-white',
              {'is-invalid': formik.touched.name && formik.errors.name},
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
            type='text'
            placeholder=''
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>

        <div className='mb-3'>
          <label className='form-label [ required ]'>Organisatie</label>
          <input
            name='orginization'
            {...formik.getFieldProps('orginization')}
            className={clsx(
              'form-control form-control-white',
              {'is-invalid': formik.touched.orginization && formik.errors.orginization},
              {
                'is-valid': formik.touched.orginization && !formik.errors.orginization,
              }
            )}
            type='text'
            placeholder=''
          />
          {formik.touched.orginization && formik.errors.orginization && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.orginization}</span>
              </div>
            </div>
          )}
        </div>

        <div className='row'>
          <div className='col-xl-6'>
            <div className='mb-3'>
              <label className='form-label [ required ]'>Telefoon</label>
              <input
                name='telephone'
                {...formik.getFieldProps('telephone')}
                className={clsx(
                  'form-control form-control-white',
                  {'is-invalid': formik.touched.telephone && formik.errors.telephone},
                  {
                    'is-valid': formik.touched.telephone && !formik.errors.telephone,
                  }
                )}
                type='text'
                placeholder=''
              />
              {formik.touched.telephone && formik.errors.telephone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.telephone}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='col-xl-6'>
            <div className='mb-3'>
              <label className='form-label [ required ]'>Email</label>
              <input
                type='email'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-white',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                placeholder=''
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-end'>
          <button
            disabled={sending}
            type='submit'
            className='btn btn-standard [ w-100 mx-autoX text-centerX ]'
          >
            {sending &&  <span>Versturen...</span>}
            {!sending && <div>
              <span> Verstuur</span>
              <i className='fas fa-solid fa-paper-plane ms-2'></i>
            </div>}
          </button>
        </div>
      </div>
    </form>
  )
}
