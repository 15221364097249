import ReactDOM from 'react-dom'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'

import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import './custom_app_styles.css'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {Helmet} from 'react-helmet';
import { toAbsoluteUrl } from './_metronic/helpers'
import {TablesWidget5} from './_metronic/partials/widgets'
import customData from './custom-settings/CustomText.json'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)

Chart.register(...registerables)

const queryClient = new QueryClient()
let title = customData.title;

// Don't know what it does, but if I remove this the app breaks
const x = <TablesWidget5 />;

ReactDOM.render(
  <div>
    <QueryClientProvider client={queryClient}>
      <Helmet>
          <title>{title}</title>
      </Helmet>
      <MetronicI18nProvider>
        <AuthProvider>
          <AppRoutes />
          <ReactQueryDevtools initialIsOpen={false} />
        </AuthProvider>
      </MetronicI18nProvider>
    </QueryClientProvider>
  </div>,

  document.getElementById('root')
)
