import {Fragment} from 'react'
import {CustomHeader} from '../modules/customComponents/CustomHeader'
import {Hero} from '../modules/customComponents/Hero'
import {Card} from '../modules/customComponents/Card'
import {Footer} from '../modules/customComponents/Footer'
import {ScrollToTop} from '../modules/customComponents/scrollToTop'
import {ContactSection} from '../modules/customComponents/ContactSection'
import {Constants} from '../../Constants/Constants'
import customData from '../../custom-settings/CustomText.json'
import * as DOMPurify from 'dompurify'

export function HomePage() {
  const environmentCustomer = process.env.REACT_APP_CUSTOMER_NAME;
  let tripleA = environmentCustomer!.toUpperCase() === Constants.TripleA.toUpperCase();
  let stories = customData.homepage.stories;
  let intro1 = customData.homepage.intro1;
  let intro2 = customData.homepage.intro2;
  let intro3 = customData.homepage.intro3;
  let lowerBanner = customData.homepage.lowerBanner.join(" ");

  return (
    <Fragment>
      <div className='mb-0' id='home'>
        <div className='bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bgX [ landing-bg ]'>
          <CustomHeader />
          <Hero />
        </div>
      </div>

      <section
        className='home-info [ py-10 py-lg-20 ] [ fs-5 ]'
      >
        <div className='container'>
          <div className='row w-100 gy-10'>
            <div className='col-md-4 home-info__left [ fw-bold fs-5 fs-lg-4 ]'>
              {intro1}
            </div>
            <div className='col-md-8 home-info__right'>
              <p className='fs-4 fs-lg-6'>
              {intro2}
              </p>
              <p className='fs-4 fs-lg-6'>
              {intro3}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='stories [ py-10 py-lg-20 ]'>
        <div className='container' data-grid='true' data-grid-item-size='md'>
          {stories.map((s) => {
            return (
              <Card key={s.heading} heading={s.heading} imgSrc={s.imgSrc} imgAlt={s.imgAlt}>
                {s.text}
              </Card>
            )
          })}
        </div>
      </section>
      <section className='highlight [ py-10 py-lg-20 ]'>
        <div className='highlight__content [ w-sm-75 w-100 ]' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(lowerBanner)}}>
        </div>
      </section>
      {tripleA &&
      <div>
        <ContactSection />
      </div>}
      <Footer />
      {tripleA &&
      <div>
        <ScrollToTop />
      </div>}
    </Fragment>
  )
}
