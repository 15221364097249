import {useState} from 'react'
import axios from 'axios'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {ContactFormTwo} from './ContactForm'
import CustomToast from './CustomToast'

export function ContactSection() {
  const [msgError, setMsgError] = useState('')
  const [msgSuccess, setMsgSuccess] = useState('')
  const [showToast, setShowToast] = useState(false)

  const sendContactRequest = async (message, name, orginization, phoneNumber, email) => {
    const body = {
      message,
      name,
      organization: orginization,
      phoneNumber,
      email,
    }

    await axios
      .post(`${process.env.REACT_APP_API_URL}/contactus`, body)
      .then((response) => {
        setMsgSuccess(response)
        console.log(response)
      })
      .catch((err) => {
        setShowToast(true)
        setMsgError(err)
        console.log(err)
        return err
      })
  }

  return (
    <>
      {msgError && (
        <CustomToast
          showToast={showToast}
          setShowToast={setShowToast}
          heading='Error'
          msg='Message not sent...'
          delay={5000}
          autohide={true}
          toastType='danger'
        />
      )}
      {msgSuccess && (
        <CustomToast
          showToast={showToast}
          setShowToast={setShowToast}
          heading='Success'
          msg='Message sent...'
          delay={5000}
          autohide={true}
          toastType='success'
        />
      )}
      <section className='contact [  ]' data-grid='true' data-grid-item-size='lg' id='contact' style={{gap: '0'}}>
        <div className='contact__info [ py-10 py-lg-20 ] [  ]'>
          <div>
            <img
              className='contact__img [ rounded w-75 ]'
              src={toAbsoluteUrl('/images/bedrijfspand-Europlaza.jpg')}
              alt='Buildings'
            />
          </div>
          <div className='contact__details'>
            <h3>WILT U MEER INFORMATIE OF EEN AFSPRAAK MAKEN?</h3>
            <p>Neemt u dan contact op met Marten de Boer</p>
            <div className='mt-6'>
              <span className='d-flex align-items-center [ my-2 ]'>
                <span className='badge badge-light badge-square'>
                  <i className='fas fa-solid fa-envelope [ fs-5 ] t-primary'></i>
                </span>
                <a
                  href='mailto:Marten.de.Boer@aaa-riskfinance.nl'
                  className='ms-2 text-white text-hover-primary'
                >
                  Marten.de.Boer@aaa-riskfinance.nl
                </a>
              </span>
              <span className='d-flex align-items-center [ my-2 ]'>
                <span className='badge badge-light badge-square'>
                  <i className='fas fa-solid fa-phone [ fs-5 ] t-primary'></i>
                </span>
                <a href='tel:31207073640' className='ms-2 text-white text-hover-primary'>
                  +31 (0)20 707 36 40
                </a>
              </span>
              <span className='d-flex align-items-center [ my-2 ]'>
                <span className='badge badge-light badge-square'>
                  <i className='fab fa-brands fa-linkedin [ fs-5 ] t-primary'></i>
                </span>
                <a href='https://nl.linkedin.com/in/marten-de-boer-60054511' className='ms-2 text-white text-hover-primary'>
                  Linkedin Profile
                </a>
              </span>
            </div>
          </div>
        </div>

        <div
          className='contact__form [ py-10 py-lg-20 ] [  ]'
          style={{backgroundColor: 'var(--bs-light-primary)'}}
        >
          <div className='w-75 mx-auto'>
            <h3>NEEM CONTACT MET ONS OP</h3>
          </div>
          <ContactFormTwo sendContactForm={sendContactRequest} msgError={msgError} />
        </div>
      </section>
    </>
  )
}
