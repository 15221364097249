import React from 'react'

export function Pagination2(props) {
  return (
    <div className='d-flex justify-content-between align-items-center flex-wrap'>
      <div id='paging_display_holder' className='d-flex align-items-center py-3'>
        <select
          value={props.pageSize}
          onChange={(e) => props.setPageSize(Number(e.target.value))}
          name=''
          id=''
          className='form-control form-control-sm font-weight-bold mr-4 border-0 bg-light [ cursor-pointer ]'
        >
          {[10, 25, 50].map((pageSizeOption) => (
            <option key={pageSizeOption} value={pageSizeOption}>
              {pageSizeOption} per pagina
            </option>
          ))}
        </select>
        {/* <span id='' className='text-muted fs-8 ms-2'>
          Displaying {props.pageIndex + 1} of {props.pageOptions.length} records
        </span> */}
      </div>
      <div className='d-flex justify-content-center align-items-center flex-wrap'>
        <div id='paging_goto_holder' className='d-flex align-items-center py-3'>
          <span id='' className='text-muted fs-8 me-2'>
            Ga naar pagina
          </span>
          <input
            type='number'
            defaultValue={props.pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value ? Number(e.target.value - 1) : 0
              props.gotoPage(pageNumber)
            }}
            className='form-control form-control-sm font-weight-bold mr-4 border-0 bg-light w-90px [ cursor-pointer ]'
          />
        </div>
        <div id='paging_holder' className='d-flex flex-wrap py-2 ms-5'>
          <button
            onClick={() => props.gotoPage(0)}
            disabled={!props.canPreviousPage}
            className='btn btn-icon btn-sm btn-light'
          >
            <i className='bi bi-arrow-left fs-4'></i>
          </button>
          <button
            onClick={() => props.previousPage()}
            disabled={!props.canPreviousPage}
            className='btn btn-icon btn-sm btn-light'
          >
            1
          </button>
          {/* <button className='btn btn-icon btn-sm btn-light'>...</button> */}
          <button
            onClick={() => props.nextPage()}
            disabled={!props.canNextPage}
            className='btn btn-icon btn-sm btn-light'
          >
            2
          </button>
          <button
            onClick={() => props.gotoPage(props.pageCount - 1)}
            disabled={!props.canNextPage}
            className='btn btn-icon btn-sm btn-light'
          >
            <i className='bi bi-arrow-right fs-4'></i>
          </button>
        </div>
      </div>
    </div>
  )
}
