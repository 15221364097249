import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/confirmjwtvalid`
export const LOGIN_URL = `${API_URL}/generatetoken`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<string>(LOGIN_URL, {
    email,
    name: password,
  }).then((res) => {
    const modal: AuthModel = {
      api_token:res.data
    };

    return modal;
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(`${GET_USER_BY_ACCESSTOKEN_URL}?token=${token}`)
}
