import React from 'react'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'

function CustomToast(props) {
  return (
    <ToastContainer style={{ position: 'fixed', bottom: '5vh', right: '3vw', zIndex: '1000' }}>
      <Toast
        onClose={() => props.setShowToast(false)}
        show={props.showToast}
        delay={props.delay}
        autohide={props.autohide}
        animation={true}
        bg={props.toastType} // 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'
      >
        <Toast.Header>
          <strong className='me-auto'>{props.heading}</strong>
        </Toast.Header>
        <Toast.Body>{props.msg}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default CustomToast
