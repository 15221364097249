import { Fragment, useState, useMemo, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table'

import { CustomHeader } from '../modules/customComponents/CustomHeader'
import { Footer } from '../modules/customComponents/Footer'
import { NewFileModal } from './dossier/_modals/NewFileModal'
import { DeleteDocumentModal } from './dossier/_modals/DeleteDocumentModal'
import { DocsActionsColumn } from './clients/components/ActionsCol'
import { GlobalFilter } from '../modules/customComponents/GlobalFilter'
import { ScrollTop } from '../../_metronic/layout/components/ScrollTop'
import { Pagination2 } from '../modules/customComponents/Pagination2'
import CustomToast from '../modules/customComponents/CustomToast'
import {FileType} from '../../Constants/Constants'
import {Constants} from '../../Constants/Constants'

export function ClientOverview() {
  const [docToDeleteId, setDocToDeleteId] = useState('')
  const [msgError, setMsgError] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [msgFileErrors, setMsgFileErrors] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;
  const environmentCustomer = process.env.REACT_APP_CUSTOMER_NAME;
  let isTripleA = environmentCustomer.toUpperCase() === Constants.TripleA.toUpperCase();
  const { id } = useParams();

  const getClientAndDocs = async () => {
    const response = await axios.get(`${API_URL}/clients/${id}/documents`)
    return response
  }

  const queryClient = useQueryClient()

  const {
    isLoading,
    isError,
    data = {data: {documents: []}},
  } = useQuery('clientAndDocs', getClientAndDocs, {
    refetchInterval:
      (curData, query) => {
        // If any document's calcuation status is not "Finished" and it is also not "Failed",
        // re-fetch after 20 seconds, otherwise re-fetch every 5 miutes to keep data up to
        // date with server.
        const intervalInMilliSeconds = 20000 // milliseconds = 20 seconds
        if (curData) {
          for (const dossier of curData.data.documents) {
            if (dossier.calculationStatus !== 'Finished' && dossier.calculationStatus !== 'Failed' && dossier.calculationStatus !== 'FailedWithErrors') {
              return intervalInMilliSeconds
            }
          }
        }
        return intervalInMilliSeconds | 300000 // milliseconds = 5 minutes
      },
  })

  const uploadDocMutation = useMutation(
    async (formData) => {
      return await axios.post(`${API_URL}/clients/${id}/documents`, formData)
    },
    {
      onSuccess: (responseData) => {
        const updatedDocData = [...data.data.documents, responseData.data]
        data.data.documents = updatedDocData
        queryClient.setQueryData('clientAndDocs', data)
      },
      onError: (error) => {
        setShowToast(true)
        setMsgError('Document upload mislukt...')
      },
    }
  )

  const deleteDocMutation = useMutation(
    (documentId) => {
      return axios.delete(`${API_URL}/clients/${id}/documents/${documentId}`)
    },
    {
      onSuccess: (responseData) => {
        const updatedDocData = data.data.documents.filter((i) => i.id !== docToDeleteId)
        data.data.documents = updatedDocData
        queryClient.setQueryData('clientDocs', data)
      },
      onError: (error) => {
        setShowToast(true)
        setMsgError('Delete document mislukt...')
      },
    }
  )

  const uploadDocHandler = async (formData) => {
    uploadDocMutation.mutate(formData)
  }

  const deleteDocHandler = () => {
    deleteDocMutation.mutate(docToDeleteId)
  }

  const downloadFile = useCallback(async (clientId, documentId, downloadType, docName) => {
    const headers = { 'Content-Type': 'blob' }
    const config = {
      method: 'GET',
      url: `${API_URL}/clients/${clientId}/documents/${documentId}?downloadType=${downloadType}`,
      responseType: 'arraybuffer',
      headers,
    }

    try {
      let documentType = 'openxmlformats-officedocument.spreadsheetml.sheet';
      let extension = '.xlsx';
      if(downloadType === FileType.reportFile) {
        documentType = 'ms-powerpoint';
        extension = '.pptx';
      }
      await axios(config)
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: `application/vnd.${documentType};charset=UTF-8`,
          })
        )
        var a = document.createElement('A');
        a.href = url;
        a.setAttribute('download', `${downloadType}_${docName}${extension}`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
    } catch (err) {
      setShowToast(true)
      setMsgError('Download mislukt...')
      console.log(err)
    }
  }, [API_URL])

  const downloadTemplate = async (templateType) => {
    const headers = { 'Content-Type': 'blob' }
    const config = {
      method: 'GET',
      url: `${API_URL}/documents/template/${templateType}`,
      responseType: 'arraybuffer',
      headers,
    }

    try {
      await axios(config)
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
          })
        )
        var a = document.createElement('A');
        a.href = url;
        a.setAttribute('download', `Template_${templateType}.xlsx`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
    } catch (err) {
      setShowToast(true)
      setMsgError('Download mislukt...')
      console.log(err)
    }
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Datum en tijd',
        accessor: c => c.dateTime,
        Cell: row => <div>{row.value.toLocaleString('nl-nl').slice(0, -3)}</div>,
        display: rowInfo => <div className='[ px-2 ]'>{rowInfo.value}</div>,
        sortType: 'datetime'
      },
      {
        Header: 'Naam dossier',
        accessor: 'docName',
        display: rowInfo => <div className='[ px-2 ]'>{rowInfo.value}</div>
      },
      {
        Header: (
          <>
            <span>Input File</span>
            <i className='bi bi-upload [ fs-6 ] [ ms-3 ]'></i>
          </>
        ),
        accessor: 'inputFile',
        disableSortBy: true,
      },
      {
        Header: (
          <>
            <span>Error File</span>
            <i className='bi bi-download [ fs-6 ] [ ms-3 ]'></i>
          </>
        ),
        accessor: 'errorFile',
        disableSortBy: true,
      },
      {
        Header: (
          <>
            <span>Output File</span>
            <i className='bi bi-download [ fs-6 ] [ ms-3 ]'></i>
          </>
        ),
        accessor: 'outputFile',
        disableSortBy: true,
      },
      {
        Header: (
          <>
            <span>Rapportage</span>
            <i className='bi bi-download [ fs-6 ] [ ms-3 ]'></i>
          </>
        ),
        accessor: 'rapportage',
        disableSortBy: true,
      },
      {
        Header: 'Verwijderen',
        accessor: 'actions',
        disableSortBy: true,
      },
    ],
    []
  )

  useEffect(() => {
    setMsgFileErrors(data.data.documents.some(function(item) {
      return item.calculationStatus === 'FailedWithError';
    }))
  }, [data.data.documents]);

  const tableData = useMemo(() => {
    const docsArr = [];
    for (const dossier of data.data.documents) {
      const createdDateTime = new Date(dossier.createdOn);
      docsArr.push({
        id: dossier.id,
        dateTime: createdDateTime,
        docName: dossier.name,
        inputFile: (
          <button
            onClick={() => downloadFile(id, dossier.id, 'inputFile', dossier.name)}
            className='btn btn-white btn-sm [ px-2 ]'
          >
            <span className='table-text-highlight fw-bolder'>EXCEL FILE</span>
            <i className='bi bi-file-earmark-excel-fill center-button [ fs-7 ] [ ms-1 ]'></i>
          </button>
        ),
        errorFile: (
          <button
            onClick={() => downloadFile(id, dossier.id, 'errorFile', dossier.name)}
            hidden={dossier.calculationStatus !== 'FailedWithError'}
            className='btn btn-white btn-sm [ px-2 ]'
          >
            <i className='bi bi-exclamation-triangle warning-triangle center-button' style={{color: 'var(--aaa-primary)'}}></i>
          </button>
        ),
        outputFile: (
          <button
            onClick={() => downloadFile(id, dossier.id, 'outputFile', dossier.name)}
            disabled={dossier.calculationStatus !== 'Finished'}
            className='btn btn-white btn-sm [ px-2 ]'
          >
            <span className='table-text-visible fw-bolder'>EXCEL FILE</span>
            {dossier.calculationStatus === 'Finished' && (
              <i className='bi bi-file-earmark-excel-fill center-button [ fs-7 ] [ ms-1 ]'></i>
            )}
            {(dossier.calculationStatus === 'Submitted' || dossier.calculationStatus === 'Unknown') && (
              <i className='bi bi-hourglass-split center-button' style={{color: 'grey'}}></i>
            )}
            {dossier.calculationStatus === 'Failed' && (
              <i className='bi bi-x-lg center-button' style={{color: 'var(--aaa-primary)'}}></i>
            )}
          </button>
        ),
        rapportage: (
          <button
            onClick={() => downloadFile(id, dossier.id, 'reportFile', dossier.name)}
            disabled={dossier.calculationStatus !== 'Finished'}
            className='btn btn-white btn-sm [ px-2 ]'
          >
            <span className='table-text-visible fw-bolder'>POWERPOINT FILE</span>
            {dossier.calculationStatus === 'Finished' && (
              <i className='bi bi-file-earmark-ppt-fill center-button [ fs-7 ] [ ms-1 ]'></i>
            )}
            {(dossier.calculationStatus === 'Submitted' || dossier.calculationStatus === 'Unknown') && (
              <i className='bi bi-hourglass-split center-button' style={{color: 'grey'}}></i>
            )}
            {dossier.calculationStatus === 'Failed' && (
              <i className='bi bi-x-lg center-button' style={{color: 'var(--aaa-primary)'}}></i>
            )}
          </button>
        ),
        actions: (
          <DocsActionsColumn
            setDocToDeleteId={setDocToDeleteId.bind(null, dossier.id)}
          />
        ),
      })
    }
    return docsArr
  }, [data.data.documents, downloadFile, id])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter, pageIndex, pageSize } = state

  if (isLoading)
    return (
      <Fragment>
        <div style={{ backgroundColor: 'white', borderBottom: '2px solid var(--aaa-primary)' }}>
          <CustomHeader />
        </div>
        <div style={{ color: 'white', fontSize: '2rem', display: 'grid', placeSelf: 'center' }}>Loading...</div>
      </Fragment>
    )
  if (isError)
    return (
      <Fragment>
        <div style={{ backgroundColor: 'white', borderBottom: '2px solid var(--aaa-primary)' }}>
          <CustomHeader />
        </div>
        <div style={{ color: 'white', fontSize: '2rem', display: 'grid', placeSelf: 'center' }}>Something went wrong...</div>
      </Fragment>
    )

  return (
    <>
      {msgError && (
        <CustomToast
          showToast={showToast}
          setShowToast={setShowToast}
          heading='Error'
          msg={msgError}
          delay={5000}
          autohide={true}
          toastType='custom-button'
        />
      )}
      <NewFileModal
        uploadDocHandler={uploadDocHandler}
        downloadTemplate={downloadTemplate}
      />
      <DeleteDocumentModal deleteDocHandler={deleteDocHandler} />
      <div style={{backgroundColor: 'white', borderBottom: '2px solid var(--aaa-primary)'}}>
        <CustomHeader />
      </div>
      <section id='' className='d-flex flex-column-fluid align-items-start container-xxl'>
        <div id='' className='content flex-row-fluid [ my-lg-15 my-5 ]'>
          <div className='card card-custom card-flush shadow' style={{borderRadius: '0'}}>
            <div className='card-header'>
              <h3 className='header-font card-title'>
                <span className='text-muted'>Overzicht dossiers</span> &nbsp;{' '}
                <small className='fs-5 text_visible'>{data.data.name}</small>
              </h3>
              <div className='card-toolbar'>
                <button
                  type='button'
                  className='btn btn-standard btn-sm'
                  data-bs-toggle='modal'
                  data-bs-target='#modalNewFile'
                >
                  <i className='bi bi-plus fs-4 me-2'></i>
                  <span>Nieuw dossier toevoegen</span>
                </button>
              </div>
            </div>
            <div className='card-body py-5'>
              <div className='table-responsive'>
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                {msgFileErrors && <span className='alert alert-warning warning-banner [ d-flex ]'>Een of meerdere inputfiles hebben errors. Klik op een icoon om de bijbehorende foutmeldingen te downloaden.</span>}
                <table className='table table-rounded table-flush' {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className='fw-bold fs-7 t-primary border-bottom border-gray-200 px-2 py-4'
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render('Header')}
                            <span>
                              {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      row.id = row.original.id
                      prepareRow(row)
                      return (
                        <tr
                          className='fw-bold py-1 border-bottom border-gray-300 fs-6'
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='card-footer border-top py-2'>
              <Pagination2
                pageIndex={pageIndex}
                pageCount={pageCount}
                pageOptions={pageOptions}
                gotoPage={gotoPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                canPreviousPage={canPreviousPage}
                previousPage={previousPage}
                nextPage={nextPage}
                canNextPage={canNextPage}
              />
            </div>
          </div>
        </div>
      </section>
      {isTripleA &&
        <div> 
          <Footer />
        </div>}   
      <ScrollTop />
    </>
  )
}
