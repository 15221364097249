import {lazy} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
//
import {ClientOverview} from '../pages/ClientOverview'
import { HomePage } from '../pages/HomePage'

const PrivateRoutes = () => {
  const ClientList = lazy(() => import('../pages/ClientList'))

  return (
    <Routes>
      <Route path='' element={<HomePage />} />
      {/* Redirect to Client List after success login/registartion */}
      <Route path='client-list' element={<ClientList />} />
      {/* Page Not Found */}
      <Route path='/client-overview/:id' element={<ClientOverview />} />

      {/* Below routes to be removed once logout fantionality is implemented */}
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/client-list' />} />
        {/* Pages */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
