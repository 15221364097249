import {useEffect} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { Unauthorized } from './components/Unauthorized'
import { LogoutPage } from './components/LogoutPage'
import customData from '../../../custom-settings/CustomText.json'

const AuthLayout = () => {
  let imgAlt = customData.logo.alt;
  let imgSrc = customData.logo.src;
  
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed no-access'
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <img
          alt={imgAlt}
          src={toAbsoluteUrl(imgSrc)}
          className='mb-12 h-75px'
        />
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          Copyright &copy; {new Date().getFullYear()}. All rights Reserved
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='unauthorized' element={<Unauthorized />} />
      <Route path='logout' element={<LogoutPage />} />
      <Route path='*' element={<Navigate to='/unauthorized' />} />
      <Route index element={<Unauthorized />} />
    </Route>
  </Routes>
)

export {AuthPage}
